@use 'src/styles' as *;

#skillhop-app .mdc-tooltip {
  font-size: 13px;
  box-shadow: 0 3px 12px rgba($color_primary, 0.2);
  border-radius: 4px;
  .mdc-tooltip__surface {
    background-color: white;
    color: $color_primary;
  }

  &.color-red-negative {
    color: $color_red_negative;
    .mdc-tooltip__surface {
      color: $color_red_negative;
    }
  }

  &.negative-tooltip {
    color: $color_red_negative;
    .mdc-tooltip__surface {
      color: $color_red_negative;
    }
    // Can you smell that? It's the smell of a workaround.
    &:before {
      content: '(';
      //display: inline-block;
      transform: translate(6px, 2px);
      position: relative;
      z-index: 1;
      top: 0 !important;
    }
    &:after {
      content: ')';
      //display: inline-block;
      transform: translate(-5px, 2px);
    }
  }

  &.menu-tooltip {
    margin-left: 10px;
  }
}

.latest-tooltip,
.tags-tooltip {
  transform: translate(-2px, 0px);

  .p-tooltip-text {
    max-width: 250px;
    min-width: 20px;
    padding: 10px;
    font-weight: 600;
    text-align: left;
    line-height: $font_size_xxl;
    font-size: $font_size_m;
  }
}

.custom-tooltip {
  transform: translate(0px, 6px);

  &.slight-right {
    transform: translate(2px, 6px);
  }
  .p-tooltip-text {
    max-width: 250px;
    padding: 10px;
    font-weight: 600;
    text-align: center;
    line-height: $font_size_xxl;
    font-size: $font_size_m;
  }
}
