@use 'src/styles' as *;
@use 'src/assets/styles/globalMixins' as m;

@mixin dropdownContainer($width: 34rem, $height: 16rem) {
  width: $width;
  height: $height;
  padding: 8px 0;

  display: flex;
  flex-direction: column;

  background-color: white;
  box-shadow: 0 3px 12px rgba($color_primary, 0.25);
  border-radius: 6px;
  transition: 0.2s ease-in;

  font-size: $font_size_m;
  color: $shade_1;

  .std-scrollbar {
    min-height: 10rem;
  }

  .table-wrapper {
    padding: 0 1rem;
    .row {
      display: grid;
      grid-template-columns: 2fr 2fr 1fr 1fr 1fr;
      gap: 24px;
      padding: 5px 0;
      border-bottom: 1px solid $shade_5;

      .row-title {
        font-size: $font-size_l;
        font-weight: bold;
      }

      .negative {
        color: $color_red_negative;
      }

      .disabled {
        color: $shade_3;
      }
    }
  }

  .buttons-container {
    @apply flex flex-col gap-1 items-baseline;
    .dropdown-button {
      @apply mt-2;
      color: $color_secondary;
      font-weight: bold;
      background: transparent;
      font-size: $font-size_l;
      line-height: 1.5;

      &:hover {
        color: $color_accent;
      }
    }
  }
}

.dropdown-icon {
  line-height: 20px;
  font-style: normal;
  &.opened {
    @include m.arrow-with-border-2(up, $size: 16px);
  }
  &.closed {
    @include m.arrow-with-border-2(down, $size: 16px);
  }
}
