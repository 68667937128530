@use 'src/styles' as styles;
@use 'sass:math';

@mixin border($type: 'left', $color: styles.$shade_5) {
  @if ($type == 'left') {
    border-left: 1px solid $color;
  }
  @if ($type == 'bottom') {
    border-bottom: 1px solid $color;
  }
  @if ($type == 'right') {
    border-right: 1px solid $color;
  }
  @if ($type == 'top') {
    border-top: 1px solid $color;
  }
}

@mixin arrow-with-border($direction: 'down', $line_width: 1px, $size: 22px) {
  display: inline-block;
  //line-height: 1;
  font-size: $size;
  border: $line_width solid styles.$color_secondary;
  box-sizing: border-box;
  border-radius: 3px;
  height: $size;
  min-height: $size;
  width: $size;
  min-width: $size;
  transition: all 0.15s ease-in-out;
  font-family: 'Titillium Web', sans-serif;
  &::after {
    content: '';
    border-style: solid;
    border-width: 0 2px 2px 0;
    border-color: styles.$color_secondary;
    padding: 3px;
    display: inline-block;
    @if $direction == down {
      transform: rotate(45deg) translate(-1px, -8px);
      @if $line_width == 1px {
        transform: rotate(45deg) translate(0px, -8px);
      }
    } @else if $direction == up {
      transform: rotate(225deg) translate(-1px, 6px);
      @if $line_width == 1px {
        transform: rotate(225deg) translate(-2px, 6px);
      }
    } @else if $direction == left {
      transform: rotate(135deg) translate(-8px, -1px);
    } @else if $direction == right {
      transform: rotate(-45deg) translate(6px, 0px);
    }
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    @include arrow-with-border-hover-state;
  }
}

@mixin arrow-with-border-2($direction: 'down', $line_width: 2px, $size: 22px) {
  width: $size;
  height: $size;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: $line_width solid styles.$color_secondary;
  border-radius: 4px;
  &:after {
    content: '\f022' !important;
    font-family: 'new-fw-icons';
    font-size: $size / 3.47; // just don't ask questions
    color: styles.$color_secondary;
    position: relative;
    top: 1px;
    transition: transform 0.2s ease-in-out;
  }

  @if ($direction == 'up') {
    &:after {
      transform: rotate(180deg);
      top: 0;
    }
  } @else if($direction == 'left') {
    &:after {
      transform: rotate(90deg);
      top: 0;
      left: -1px;
    }
  } @else if($direction == 'right') {
    &:after {
      transform: rotate(-90deg);
      top: 0;
    }
  }
}

@mixin arrow-with-border-hover-state() {
  background: styles.$color_secondary;
  &::after {
    border-color: white !important;
  }
}

@mixin interaction-bar-button {
  padding: 12px 0;
  align-self: flex-end;
  margin-top: auto;
  background-color: styles.$color_accent;
  color: styles.$color_white;
  width: 100%;
  flex: 1;
  font-weight: 400;
  font-size: 16px;
  border-radius: 6px;
}

@mixin sk-scrollbar() {
  @-moz-document url-prefix() {
    scrollbar-width: thin !important;
    scrollbar-color: styles.$shade_2 transparent;
  }

  /* Scrollbar Styling */
  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
    display: block;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: styles.$shade_2;
  }
}

// deprecated
@mixin custom-mat-input-form() {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  .refresh-logo {
    font-weight: 600;
    color: styles.$shade_2;
    font-size: styles.$font_size_title_l;
    margin: auto;
  }

  // used for upload purpose
  .options {
    font-weight: bold;
    order: 1;

    &.animate-shake {
      padding: 10px;
    }

    .upload-btn {
      float: right;
    }

    .form {
      width: 100%;
    }

    ::ng-deep {
      .form {
        .mat-form-field {
          width: 100%;
          font-size: 14px;
        }

        &.upload {
          &.hide-underline
            .mat-form-field-appearance-legacy.mat-form-field-disabled
            .mat-form-field-underline {
            visibility: hidden;
          }

          .custom-upload-border {
            border: 1px solid styles.$shade_5;
            border-radius: 6px;
            padding: 10px;
          }

          ngx-mat-file-input {
            font-style: italic;
            font-weight: normal;
            color: styles.$shade_2;
          }

          .icon-remove-circle-x {
            color: styles.$color_secondary;
          }

          .icon-upload {
            color: styles.$color_secondary;
            display: flex;
            align-items: center;
            margin-right: 0.5rem;
            font-size: 16px;
          }

          .mat-input-element:disabled {
            color: styles.$color_secondary;
            font-size: styles.$font_size_l;
            font-weight: 400;
          }

          .custom-placeholder .mat-input-element:disabled {
            color: styles.$color_secondary;
            font-weight: bold;
            font-style: italic;
            font-size: styles.$font_size_l;
          }
        }

        .input {
          display: flex;
          align-items: center;
        }

        .input.animate-shake {
          margin: 6px;
          padding: 2px;
        }
      }
    }
  }

  .form {
    display: flex;
    color: styles.$color_primary;
    width: 100%;

    .input {
      &.disabled ::ng-deep .mat-select-value {
        color: styles.$color_disabled !important;
      }
    }

    &.disabled ::ng-deep {
      .mat-form-field {
        input,
        mat-select {
          color: styles.$color_disabled;
        }
      }
    }

    ::ng-deep {
      .mat-form-field-appearance-legacy.mat-form-field-disabled
        .mat-form-field-underline {
        background-image: none;
      }

      // todo check later if anything else is wrong with using this wrapper
      .mat-button-wrapper {
        display: flex;
        justify-content: center;
      }

      .mat-form-field {
        width: 100%;

        mat-label {
          text-transform: none;
        }

        .mat-form-field-suffix {
          top: -0.3rem;
        }

        input,
        textarea,
        mat-select {
          font-weight: 600;
          font-size: styles.$font_size_xl;
          padding: 0.4rem 0;
          margin: 0;

          &.status {
            text-transform: capitalize;
          }
        }
      }

      .mat-select-value {
        &.accepted {
          color: styles.$color_accept;
        }

        &.declined {
          color: styles.$color_reject;
        }
      }

      .mat-select-value-text {
        color: inherit;
        font-family: Titillium Web;
      }

      .drop-down.mat-form-field {
        width: 100%;

        .mat-select-value {
          color: styles.$color_primary !important;
        }
      }
    }

    &.disabled ::ng-deep .drop-down.mat-form-field .mat-select-value {
      color: styles.$color_disabled !important;
    }

    .input-text {
      width: 100%;
      font-size: styles.$font_size_xl;

      textarea {
        margin-top: 0.5rem;
        font-weight: normal;
      }
    }
  }
}

@mixin dropdown-flexible-options-list(
  $minWidth: 721px,
  $marginLeft: 78px,
  $padding: 1.25rem,
  $grid: 120px auto 1fr 70px,
  $minHeight: 208px
) {
  overflow: scroll;
  min-width: $minWidth; // width of the input
  min-height: $minHeight;
  padding: $padding;
  align-items: center;
  background-color: white;
  transition: 0.2s ease-in;
  box-shadow: 0 3px 10px rgb(6 26 79 / 10%);
  border-radius: 6px;

  .row {
    border-color: styles.$shade_5;
    display: grid;
    grid-template-columns: $grid;
    column-gap: 15px;
    align-items: baseline;
  }
}

@mixin dropdown-fixed-options-list(
  $minWidth: 721px,
  $padding: 1.25rem,
  $grid: 120px auto 1fr 70px
) {
  overflow: scroll;
  min-width: $minWidth; // width of the input
  min-height: 13rem;
  padding: $padding;
  align-items: center;
  background-color: white;
  transition: 0.2s ease-in;
  box-shadow: 0 3px 10px rgb(6 26 79 / 10%);
  border-radius: 6px;
  position: fixed;
  left: 0;
  box-sizing: border-box;

  .std-scrollbar {
    min-height: 12.5rem;
  }

  .row {
    border-color: styles.$shade_5;
    display: grid;
    grid-template-columns: $grid;
    column-gap: 15px;
    align-items: baseline;
    padding: 13px 0;
    border-bottom: 1px solid styles.$shade_5;

    &:last-child {
      border-bottom: none;
    }
  }
}

@mixin active-underline($border-size, $color) {
  &.selected {
    position: relative;
    color: styles.$color_primary;
    font-weight: 600;

    &:before {
      content: '';
      width: 10%;
      border-top: $border-size solid $color;
      position: absolute;
      transition: 0.2s ease-in;
      height: 1px;
      margin-top: -1px;
      bottom: 0;
    }

    &:hover {
      cursor: pointer;

      &:before {
        width: 100%;
      }
    }
  }
}

@mixin input-no-underline($color: color_secondary) {
  ::ng-deep {
    .mat-form-field-underline {
      display: none;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0.5em;
    }

    .mat-form-field-label-wrapper {
      top: 3px;
      padding-top: 0;

      label {
        top: 0;
        color: $color !important;
        font-weight: 700;
        line-height: 25px;
        font-size: 13px;
      }
    }

    .mat-form-field-infix {
      width: 5rem;
      padding-bottom: 0;
      border-top: 0 solid transparent;
    }

    input {
      padding-left: 0;
      color: $color;
    }
  }
}

@mixin custom-btn {
  border-radius: 6px;
  width: 105px;
  transition: all 0.1s ease-in-out;
  cursor: pointer;

  &.discard-btn {
    border: 1px solid styles.$color_secondary;
    color: styles.$color_secondary;

    &:hover {
      color: white;
      background: styles.$color_secondary;
    }
  }

  &.send-btn {
    background: styles.$color_accent;
    color: white;

    &:hover {
      filter: brightness(1.1);
    }
  }

  &.save-filled-blue {
    background: styles.$color_secondary;
    color: white;

    &:hover {
      filter: brightness(1.1);
    }
  }
}

@mixin negative($margin: 0px) {
  color: styles.$color_red_negative;
  &:before {
    content: '(';
    display: inline-block;
    margin-right: -$margin;
  }
  &:after {
    content: ')';
    display: inline-block;
    margin-left: -$margin;
  }
}

@mixin options-list-animation-wrapper() {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: styles.$color_white;
  transition: 0.2s ease-in;
  box-shadow: 0 3px 10px rgba(6, 26, 79, 0.1);
  border-radius: 6px;
  position: fixed;

  .options-list {
    font-weight: normal;
    font-size: styles.$font_size_m;
    color: styles.$shade_1;
    display: flex;
    flex-direction: column;
    padding: 10px;

    .row {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 1fr;
    }

    .btn-option:hover {
      color: styles.$color_accent;
      font-weight: 600;
    }
  }
}

@mixin text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@mixin question-icon($bg: styles.$shade_1) {
  display: inline-block;
  background-color: $bg;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  &:after {
    content: '?';
    font-weight: 600;
    position: relative;
    top: -4px;
    left: 6px;
    font-size: 14px;
    color: white;
  }
}
