$color_primary: #071743; // Primary / Navy
$color_secondary: #4392f1;
$color_accent: #ffba49;
$color_accent_shade_1: #f3c986;
$color_accent_shade_2: #d7ca78;
$color_accent_shade_3: #c5c09c;
$color_accent_shade_4: #fffcf6;
$color_white: #ffffff;
$color_black: #000000;
$color_grey: #bdbdbd;
$color_disabled: rgba(0, 0, 0, 0.38); // taken from mat-disabled
$color_grey_shade_0: #9d9d9d;
$color_grey_shade_1: #bbc7e6;
$color_grey_shade_2: #b0bde2;
$color_accept: #27ae60;
$color_posted: #9b51e0;
$color_progress: #d197e0;
$color_added: #219653;
$color_light_green: #6fcf97;
$color_light_green2: #70c993;
$color_light_green3: #2ecc71;
$color_blue_shade_0: #44588e;
$color_blue_shade_1: #0a1b4c;
// todo: refactor these red names
$color_red_negative: #f14b43;
$color_reject: #eb5757;
$color_light_red: #ff6767;
$color_pink: #ff8a8a;
$color_red_light: #f9a6a6;

$color_purple: #8e44ad;
$color_invoice: #8e44ad;
$color_contract: #b5bfc0;
$color_misc: #3498db;

$shade_1: #2c427e;
$shade_2: #667dbc;
$shade_3: #a5b4de;
$shade_4: #cbd7f7;
$shade_5: #e2e7f5;
$shade_6: #edf2ff;
$shade_7: #f6f8ff;
$shade_8: #dbeafb;
$shade_9: #f9f9fa;

$font: Titillium Web;

$mobile_width: 912px;
$menu_width: 60px;
$menubar_breakpoint: 1080px;

$font_size_xs: 10px;
$font_size_s: 11px;
$font_size_m: 12px;
$font_size_l: 13px;
$font_size_xl: 14px;
$font_size_xxl: 16px;

$font_size_icon: 18px;
$font_size_icon_bigger: 22px;

$font_size_title_s: 20px;
$font_size_title_m: 24px;
$font_size_title_l: 26px;
$font_size_title_xl: 28px;

$sidebar_padding: 25px;
